<template>
    <div class="vx-col w-2/3">
        <vx-card class="mb-base h-auto" title="Tus solicitudes.">
            <vs-table ref="table" stripe :data="projects" noDataText="No hay datos disponibles">
                <template slot="thead">
                    <vs-th class="bold">IDENTIFICADOR</vs-th>
                    <vs-th class="bold">TIPO DE SOLICITUD</vs-th>
                    <!-- <vs-th class="bold">ESTADO</vs-th> -->
                    <vs-th class="bold">MONTO</vs-th>
                    <vs-th class="bold">FECHA DE SOLICITUD</vs-th>
                    <vs-th class="bold">ESTADO</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            <a @click="showMore(tr.id, tr)" class="clickable-img">{{ tr.id }}</a>
                        </vs-td>
                        <vs-td class="bold">
                            {{ translateLoanType(tr.loan_type) }}
                        </vs-td>
                        <!-- <vs-td>
                            {{ tr.status }}
                        </vs-td> -->
                        <vs-td>
                            {{ mxnFormat(tr.total_cost,2) }}
                        </vs-td>
                        <vs-td>
                            {{ midDateFormat(tr.created_at) }}
                        </vs-td>
                        <vs-td>
                            {{ translateOnboardingStep(tr.loan_request_step, tr.is_moral) }}
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
    </div>
</template>
 
<script>
import formatHelper from '@mixins/formatHelper';
import dateHelper from '@mixins/dateHelper'

export default {
    name: 'LoanTable',
    mixins: [formatHelper,dateHelper],
    data() {
        return {
            projects: []
        }
    },
    async beforeMount() {
        this.showLoading(true);
        this.getLoans();
        this.showLoading(false);
    },

    methods: {
        async getLoans() {
            try {
                const res = await axios.get(`api/loan-onboarding/get/${this.ApplicantId}/loans`);
                this.projects = res.data;
            } catch (error) {
                console.log(error);
                this.failedOperationNotif("Algo salió mal.");
            }
        },
        translateLoanType(loanType)
        {
            const LoanTypes = {
                '1': 'Crédito ecotecnologías',
                '5': 'Crédito personal',
                '4': 'Crédito PYME'
            }

            return LoanTypes[loanType]||"NA";
        },
        translateOnboardingStep(step, isMoral)
        {
            let LoanTypes = {};
            if(isMoral) {
                LoanTypes = {
                    'pm_1_business_loan_kickoff'            : 'Confirma tu dirección de operación.',
                    'pm_2_business_address_confirmation'    : 'Confirma tu dirección personal.',
                    'pm_3_personal_profile_address'         : 'Valida tu RFC y CURP.',
                    'pm_4_personal_profile_curp'            : 'Autoriza la consulta  de Buró de Crédito',
                    'pm_5_personal_profile_sic_check'       : 'Estamos preparando tu oferta.',
                    'pm_6_credit_acceptance'                : '¡Felicidades, tienes un crédito aprobado!'
                }
            } else {
                LoanTypes = {
                    'pf_1_applicant_address'        : 'Cuéntanos más sobre ti.',
                    'pf_2_tech_address'             : '¿A qué te dedicas?',
                    'pf_3_loan_profile'             : '¿A qué te dedicas?',
                    'pf_4_occupation'               : 'Platícanos cual es tu ocupación.',
                    'pf_5_job_profile'              : 'Falta poco, cuentanos sobre tu empleo.',
                    'pf_6_income'                   : 'Ahora, compártenos información sobre tus ingresos y tus gastos.',
                    'pf_7_expenses'                 : 'Ya casi está listo, acepta la revisión de tu historial crediticio.',
                    'pf_8_loan_checkpoint'          : 'Tu solicitud ha sido enviada y en breve un agente de RedGirasol se pondrá en contacto contigo.',
                    'pf_9_references'               : 'Tu solicitud ha sido enviada y en breve un agente de RedGirasol se pondrá en contacto contigo.',
                    'pf_10_credit_acceptance'       : "Ya haz aceptado tu crédito. ¡Felicidades!",
                    'pf_11_guarantee_data'          : "Compártenos tus documentos.",
                    'pf_12_documents_and_identity'  : "Firma los contratos de tu crédito.",
                    'pf_13_contracts_sign'          : "Felicidades! Haz terminado el proceso."
                }
            }

            return LoanTypes[step]||"Inicia con tu solicitud de crédito, cuentanos más sobre tu domicilio.";
        },
        showMore(id, project) {
            if(
                (project.loan_request_step == "pf_9_references" || project.loan_request_step == "pm_5_personal_profile_sic_check" || (project.loan_request_step == "pf_8_loan_checkpoint" && project.loan_type == 1)) && project.status_int < 4) {
                this.$vs.dialog({
                    color: 'success',
                    title: '¡Solicitud en proceso!',
                    text: `Ahora tu solicitud se encuentra en proceso de aprobación, te notificaremos una vez que esté lista. ¡Gracias por confiar en RedGirasol!`,
                    acceptText: 'Enterad@'
                });
                return;
            }
            this.$router.push({ name: 'applicantLoanDetail', params: { id: id } });
        }
    }
}
</script>
 
<style scoped>
.pre-formatted {
    white-space: pre;
}
</style>